import { FC, HTMLAttributes } from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import * as styles from './styles'

type AutocompleteNoResultsGenericMessageProps = {
  inputValue: string
}

const AutocompleteNoResultsGenericMessage: FC<
  AutocompleteNoResultsGenericMessageProps & Omit<HTMLAttributes<HTMLDivElement>, 'role'>
> = ({ inputValue, ...rest }) => {
  const theme = useTheme()

  return (
    <div {...rest} role="alert" css={styles.noResultText(theme)}>
      <Typography css={styles.noResultTitle} color="textPrimary" variant="h3">
        <FormattedMessage id="search_no_results_title" values={{ input: inputValue }} />
      </Typography>
      <Typography color="textSecondary">
        <FormattedMessage id="search_no_results_body" />
      </Typography>
    </div>
  )
}

export default AutocompleteNoResultsGenericMessage
