import { FC, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import createCache from '@emotion/cache'
import { CacheProvider, Global } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AutocompleteSdk, SearchSuggestionsSdk, User, UserSdk } from 'invictus-sdk-typescript'

import { DEFAULT_LOCALE } from '@Constants/locale'
import { FeaturesProvider } from '@Contexts/features/FeaturesContext'
import { SdkProvider } from '@Contexts/sdk/SdkContext'
import { useAllMessages } from '@Hooks/i18n'
import { lightTheme } from '@Material/theme'
import { Features } from '@Types/features'
import Locale from '@Types/locale'
import Market from '@Types/market'
import { formatToClientMarket } from '@Utils/market'
import { getDeviceSettings } from '@Utils/user'

import { externalWidgetPublicRuntimeConfig, getWidgetFrontendApiConfiguration } from '../config'
import { AVENIR_HEAVY_WOFF, AVENIR_HEAVY_WOFF2 } from '../fonts/avenirHeavy'
import { AVENIR_MEDIUM_WOFF, AVENIR_MEDIUM_WOFF2 } from '../fonts/avenirMedium'

import BookingHeader from './_internal/BookingHeader'
import BookingForm from './form/BookingForm'
import { BookingWidgetInit, BookingWidgetParameters } from './Booking.types'
import * as styles from './styles'

const getAutocompleteSDK = (clientMarket: Market): AutocompleteSdk =>
  AutocompleteSdk.init(getWidgetFrontendApiConfiguration(), new UserSdk(new User(getDeviceSettings({ clientMarket }))))

const getSearchSuggestionsSDK = (clientMarket: Market): SearchSuggestionsSdk =>
  SearchSuggestionsSdk.init(
    getWidgetFrontendApiConfiguration(),
    new UserSdk(new User(getDeviceSettings({ clientMarket })))
  )

type BookingAppProps = {
  locale?: Locale
  parameters?: BookingWidgetParameters
}

export const BookingApp: FC<BookingAppProps> = ({ locale = DEFAULT_LOCALE, parameters }) => {
  const { messages } = useAllMessages(locale)

  const autocompleteSdk = useMemo(() => getAutocompleteSDK(formatToClientMarket(locale)), [locale])
  const searchSuggestionsSdk = useMemo(() => getSearchSuggestionsSDK(formatToClientMarket(locale)), [locale])

  const queryClient = new QueryClient()

  const { effinityTrackingUrl } = externalWidgetPublicRuntimeConfig

  if (!messages) {
    return null
  }

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale as string} defaultLocale={locale} messages={messages}>
        <SdkProvider customSdk={{ autocompleteSdk, searchSuggestionsSdk }}>
          <div css={styles.animatedContainer}>
            <BookingHeader />
            <BookingForm {...parameters} effinityTrackingUrl={effinityTrackingUrl} />
          </div>
        </SdkProvider>
      </IntlProvider>
    </QueryClientProvider>
  )
}

export const init: BookingWidgetInit = (containerId, parameters, locale) => {
  // The font-face configurations have to be put in the head of the document
  const headStyleElement = document.createElement('style')
  headStyleElement.innerHTML = `
    @font-face {
      font-family: Avenir-Heavy;
      src: url("data:application/font-woff2;base64,${AVENIR_HEAVY_WOFF2}") format("woff2"), url("data:application/font-woff;base64,${AVENIR_HEAVY_WOFF}") format("woff");
      font-weight: 800;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: Avenir-Medium;
      src: url("data:application/font-woff2;base64,${AVENIR_MEDIUM_WOFF2}") format("woff2"), url("data:application/font-woff;base64,${AVENIR_MEDIUM_WOFF}") format("woff");
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
  `
  document.head.appendChild(headStyleElement)

  const container = document.getElementById(containerId) as HTMLElement
  const styleElement = document.createElement('style')
  const shadowRootElement = document.createElement('div')
  shadowRootElement.className = 'shadow_root'

  const widgetTheme = createTheme(lightTheme, {
    components: {
      MuiDialog: {
        defaultProps: {
          container: shadowRootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: shadowRootElement,
        },
      },
      MuiPopover: {
        defaultProps: {
          container: shadowRootElement,
        },
      },
    },
  })

  const shadowContainer = container.shadowRoot ?? container.attachShadow({ mode: 'open' })
  shadowContainer.appendChild(styleElement)
  shadowContainer.appendChild(shadowRootElement)

  const cache = createCache({
    key: 'css',
    container: styleElement,
    insertionPoint: shadowRootElement,
  })

  createRoot(shadowRootElement).render(
    // TODO (https://jira.vsct.fr/browse/IVTS-70505) : on initialise les features à vide pour le moment
    // il faudra à terme appeler une api route NextJS afin de pouvoir consommer les features ici
    // et que le widget puisse s'adapter en fonction des features activées
    <FeaturesProvider features={{} as Features}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={widgetTheme}>
          <CssBaseline />
          <Global styles={styles.global} />
          <BookingApp parameters={parameters} locale={locale} />
        </ThemeProvider>
      </CacheProvider>
    </FeaturesProvider>
  )
}
